import(/* webpackMode: "eager", webpackExports: ["RB2BElement","PHProvider"] */ "/vercel/path0/typescript/fiddle-frontend/app/_components/PosthogProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/vercel/path0/typescript/fiddle-frontend/app/_components/ThemeProvider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/typescript/fiddle-frontend/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/typescript/fiddle-frontend/app/PostHogPageView.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/vercel/path0/typescript/fiddle-frontend/components/ui/toaster.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/typescript/node_modules/.pnpm/next@15.1.5_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager", webpackExports: ["ErrorBoundary"] */ "/vercel/path0/typescript/node_modules/.pnpm/react-error-boundary@4.1.2_react@18.3.1/node_modules/react-error-boundary/dist/react-error-boundary.esm.js");
