import(/* webpackMode: "eager" */ "/vercel/path0/typescript/node_modules/.pnpm/next@15.1.5_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/typescript/node_modules/.pnpm/next@15.1.5_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/components/client-segment.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/typescript/node_modules/.pnpm/next@15.1.5_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/typescript/node_modules/.pnpm/next@15.1.5_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/components/http-access-fallback/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/typescript/node_modules/.pnpm/next@15.1.5_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/typescript/node_modules/.pnpm/next@15.1.5_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/components/render-from-template-context.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/typescript/node_modules/.pnpm/next@15.1.5_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/lib/metadata/metadata-boundary.js");
